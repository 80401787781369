import React, { useCallback } from 'react';
import {
  NewDisplayHandleLink,
  NewDisplayHandleAction,
  NewDisplayHandleLinkArgs,
} from '@display-component/shared';
import { useRouter } from 'next/router';
import { authGateway } from '@/di-container/auth/gateway/client';
import {
  NewTextButton,
  useAlert,
  useConfirm,
  useToast,
} from '@design-system/pc';
import { ALERT_Z_INDEX, CONFIRM_Z_INDEX, TOAST_Z_INDEX } from '@/styles';

export type HandleOpenAppArgs = {
  target?: string;
  channel?: string;
  module?: string;
  value?: string;
};

const useDisplayComponent = () => {
  const router = useRouter();

  /**
   * appLink와 defaultUrl을 받아 타겟 url로 랜딩하는 함수
   * @param {NewDisplayHandleLinkArgs} appLink 전시 데이터를 담고 있습니다.
   * @param {string} defaultUrl defaultUrl 을 입력하면 linkUrl 이 비어있을때 지정된 defaultUrl 로 이동합니다. linkUrl 과 defaultUrl 둘다 없다면 '/' 로 이동합니다.
   * @returns void
   */
  const handleOpenUrl = useCallback(
    (appLink: NewDisplayHandleLinkArgs, defaultUrl?: string) => {
      if (typeof window === 'undefined') {
        return;
      }

      const url = appLink.applink?.linkUrl ?? (defaultUrl || '/');

      if (appLink.isNewWindow) {
        window.open(url, '_blank');
      } else {
        window.location.href = url;
      }
    },
    [],
  );

  const handleNewLink = useCallback<NewDisplayHandleLink>(
    (appLink) => {
      console.log('[TEST-DISPLAY - appLink]', appLink);
      if (
        (appLink?.applink && !appLink.applink.appLinkTarget) ||
        appLink?.applink?.appLinkTarget === 'URL'
      ) {
        handleOpenUrl(appLink);
      }

      if (appLink?.applink?.appLinkTarget === 'GNB') {
        switch (appLink?.applink?.appLinkChannel) {
          case 'MAIN':
            handleOpenUrl(appLink);
            break;
          case 'MALL':
            handleOpenUrl(appLink, '/furnishing');
            break;
          case 'HOMEIDEA':
          case 'REMODELING':
            handleOpenUrl(appLink, '/interior');
            break;
          default:
            handleOpenUrl(appLink);
            break;
        }
      }
      if (appLink?.applink?.appLinkTarget === 'BNB') {
        switch (appLink?.applink?.appLinkChannel) {
          case 'MAIN':
            handleOpenUrl(appLink);
            break;
          case 'FURNISHING':
            handleOpenUrl(appLink, '/furnishing');
            break;
          case 'INTERIOR':
            handleOpenUrl(appLink, '/interior');
            break;
          case 'SHOPSEARCH':
            handleOpenUrl(appLink, '/shop/search');
            break;
          case 'MY':
            handleOpenUrl(appLink, '/my');
            break;
          default:
            handleOpenUrl(appLink);
            break;
        }
      }
      if (appLink?.applink?.appLinkTarget === 'CATEGORY') {
        handleOpenUrl(appLink);
      }

      if (appLink?.applink?.appLinkTarget === 'DISPLAY') {
        handleOpenUrl(appLink);
      }

      if (appLink?.applink?.appLinkTarget === 'PLAN') {
        handleOpenUrl(
          appLink,
          `${appLink?.applink?.linkUrl}${
            appLink.applink.appLinkAddQuery
              ? '&' + appLink.applink.appLinkAddQuery
              : ''
          }`,
        );
      }

      if (appLink?.goodsInfo) {
        handleOpenUrl(appLink, `/goods/${appLink.goodsInfo.gdsNo}`);
      }
    },
    [handleOpenUrl],
  );

  const handleLogin = useCallback(async () => {
    await authGateway().login.exec();
  }, []);

  const { toast } = useToast();

  const { handleOpen: handleAlertOpen } = useAlert();
  const { handleOpen: handleConfirmOpen, handleClose: closeConfirm } =
    useConfirm();

  const handleGoScrap = useCallback(() => {
    router.push(
      `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/mypage/wishList.do`,
    );
  }, [router]);

  const handleAction = useCallback<NewDisplayHandleAction>(
    ({ message, action, type }) => {
      console.log('[action]', { message, action, type });

      if (action === 'scrap') {
        toast.success('찜 목록에 추가하였습니다.', {
          placement: 'bottom',
          variant: 'button',
          actionButton: (
            <NewTextButton
              size="lg"
              isUnderline
              color="color_chip.white"
              onClick={handleGoScrap}
              width={74}
            >
              찜 목록가기
            </NewTextButton>
          ),
          offset: 87,
          zIndex: TOAST_Z_INDEX,
        });
      } else {
        switch (type) {
          case 'toast':
            toast.success(message, {
              alignment: 'center',
              placement: 'bottom',
              variant: 'basic',
              offset: 87,
              zIndex: TOAST_Z_INDEX,
            });
            break;

          case 'alert':
            handleAlertOpen({ content: message, zIndex: ALERT_Z_INDEX });
            break;

          case 'confirm':
            if (action === 'login') {
              console.log('[confirm]');
              handleConfirmOpen({
                cancelText: '취소',
                confirmText: '로그인',
                content: message,
                width: '370px',
                isShowCloseButton: true,
                zIndex: CONFIRM_Z_INDEX,
                handleConfirm: () => {
                  handleLogin();
                },
                handleCloseButton: () => {
                  closeConfirm();
                },
              });
            } else {
              handleConfirmOpen({
                cancelText: '취소',
                confirmText: '로그인',
                content: message,
                width: '370px',
                zIndex: CONFIRM_Z_INDEX,
              });
            }
            break;

          default:
            break;
        }
      }
    },
    [
      closeConfirm,
      handleAlertOpen,
      handleConfirmOpen,
      handleGoScrap,
      handleLogin,
      toast,
    ],
  );

  return {
    handleNewLink,
    handleAction,
  };
};

export default useDisplayComponent;
