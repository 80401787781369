var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ozG9k3BT-3IStnvQSxZk1"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

if (
  process.env.NEXT_PUBLIC_STAGE === 'production' ||
  process.env.NEXT_PUBLIC_STAGE === 'staging'
) {
  Sentry.init({
    environment: process.env.NEXT_PUBLIC_STAGE,
    dsn: process.env.NEXT_PUBLIC_WEB_SENTRY_DSN,
    tracesSampleRate: 0.05,
    ignoreErrors: [/Blocked a frame with origin/],
  });
}
