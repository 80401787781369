import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BannerSettingDataResponse } from '@display-component/shared';
import { displayGateway } from '@/di-container/display/gateway/client';
import useSWR from 'swr';
import { SwiperRef } from 'swiper/react';
import { GetTemplateComponentsType } from '@display-component/pc/dist/types/display-service';

export interface UseHome {
  data: GetTemplateComponentsType[];
}

const useHome = ({ data: displayData }: UseHome) => {
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [activeCategory, setActiveCategory] = useState<number>(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = useCallback(() => {
    setIsLoaded(true);
  }, []);

  const displayGTM = useMemo(() => {
    return displayData[0]?.attribute?.body?.gtm;
  }, [displayData]);

  const welcomeBannerList = useMemo(() => {
    return (displayData[0]?.data?.content as BannerSettingDataResponse[]) || [];
  }, [displayData]);

  const { data: categoryData } = useSWR('MAIN_SECTION_CATEGORY', () =>
    displayGateway().getMainSectionMenus.exec({ typeValue: 'PC' }),
  );

  const mallMenus = useMemo(
    () => categoryData?.mallMenu || [],
    [categoryData?.mallMenu],
  );

  const remodelingMenus = useMemo(
    () => categoryData?.remodelingMenu || [],
    [categoryData?.remodelingMenu],
  );

  const handleChangeSwiper = useCallback((index: number) => {
    setActiveIndex(index);
  }, []);

  const handleVisibleText = useCallback(() => {
    const slides = document.querySelectorAll(
      '.main-wrapper .swiper-wrapper > .swiper-slide',
    );
    setTimeout(() => {
      slides.forEach((slide, idx) => {
        if (idx === activeIndex) {
          slide.classList.add('visible_main_text');
        } else {
          slide.classList.remove('visible_main_text');
        }
      });
    }, 400);
    setTimeout(() => {
      slides.forEach((slide, idx) => {
        if (idx === activeIndex) {
          slide.classList.add('visible_sub_text');
        } else {
          slide.classList.remove('visible_sub_text');
        }
      });
    }, 500);
  }, [activeIndex]);

  const handleGoLink = useCallback((linkUrl: string) => {
    window.location.href = linkUrl;
  }, []);

  const handleClickBigCategory = useCallback((index: number) => {
    setActiveCategory(index);
  }, []);

  const swiperRef = useRef<SwiperRef>(null);

  const handleClickTopButton = useCallback(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(1);
    }
  }, []);

  useEffect(() => {
    welcomeBannerList.length > 0 && isLoaded && setActiveIndex(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [welcomeBannerList, isLoaded]);

  useEffect(() => {
    handleVisibleText();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  return {
    isLoaded,
    handleImageLoad,
    activeIndex,
    activeCategory,
    displayGTM,
    welcomeBannerList,
    mallMenus,
    remodelingMenus,
    handleChangeSwiper,
    handleGoLink,
    handleClickBigCategory,
    handleClickTopButton,
    swiperRef,
  };
};

export default useHome;
