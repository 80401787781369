import React from 'react';
import { SectionMenu } from '@store/content';
import useCategoryMenu from './hooks/useCategoryMenu';
import styled from 'styled-components';
import TrackingService from '@/services/tracking';
import {
  HStack,
  VStack,
  Text,
  Box,
  NewBadge,
  DotBadge,
} from '@design-system/pc';

type CategoryMenuProps = {
  sectionMenuData?: SectionMenu | null | undefined;
  isShow?: boolean;
};

function CategoryMenu({ sectionMenuData, isShow = false }: CategoryMenuProps) {
  const {
    handleEnteredGroupCategoryNo,
    enteredGroupCategoryNo,
    handleEnteredMiddleCategoryNo,
    enteredMiddleCategoryNo,
    enteredGroupCategory,
    enteredMiddleCategory,
  } = useCategoryMenu({ sectionMenuData });

  return (
    <CategoryMenuWrap
      border="1px solid"
      backgroundColor="color_chip.white"
      isShow={
        isShow &&
        sectionMenuData?.groupCategoryList !== undefined &&
        sectionMenuData?.groupCategoryList.length > 0
      }
      onMouseLeave={() => {
        handleEnteredGroupCategoryNo('');
        handleEnteredMiddleCategoryNo('');
      }}
      height={531}
    >
      <HStack alignment="start" height="100%">
        <CategoryListWrap height="100%" overflowY="auto">
          {sectionMenuData?.groupCategoryList.map((item, index) => {
            return (
              <GroupCategoryItemWrap
                key={`grpCtgList-key-${index}-${item.categoryName}`}
                onMouseEnter={() =>
                  handleEnteredGroupCategoryNo(item.categoryNo)
                }
                onClick={() => (window.location.href = item.linkUrl || '/')}
                backgroundColor={
                  enteredGroupCategoryNo === item.categoryNo
                    ? 'color_chip.primary'
                    : 'color_chip.white'
                }
                {...TrackingService.tagGTM(`mall_gnb_category_depth1_button`, {
                  'menu-value': item.categoryName,
                })}
              >
                <HStack space={2}>
                  <Text
                    variant={
                      item.isHighLight === 'Y' ? 'label_md_b' : 'label_md_m'
                    }
                    // 쌤페스타 한정 강조 색상 노출 하드코딩 요청 건. 20511(홈퍼니싱), 20512(인테리어)
                    color={
                      item.categoryNo === '20511' || item.categoryNo === '20512'
                        ? '#FC8300'
                        : enteredGroupCategoryNo === item.categoryNo
                        ? 'color_chip.white'
                        : 'color_chip.primary'
                    }
                  >
                    {item.categoryName}
                  </Text>
                  {item.isHighLight === 'Y' && (
                    <NewBadge variant="style1"></NewBadge>
                  )}
                </HStack>
              </GroupCategoryItemWrap>
            );
          })}
          {sectionMenuData?.menuList.map((item, index) => {
            return (
              <GroupCategoryItemWrap
                key={`grpCtgList-key-${index}-${item.menuDataNm}`}
                onMouseEnter={() =>
                  handleEnteredGroupCategoryNo(item.menuDataNm)
                }
                onClick={() =>
                  (window.location.href = item?.appLinkInfoDto?.linkUrl || '/')
                }
                backgroundColor={
                  enteredGroupCategoryNo === item.menuDataNm
                    ? 'color_chip.primary'
                    : 'color_chip.white'
                }
                {...TrackingService.tagGTM(`mall_gnb_category_depth1_button`, {
                  'menu-value': item.menuDataNm,
                })}
              >
                <HStack alignment="start" space={2}>
                  <Text
                    variant={
                      item.isHighLight === 'Y' ? 'label_md_b' : 'label_md_m'
                    }
                    color={
                      enteredGroupCategoryNo === item.menuDataNm
                        ? 'color_chip.white'
                        : 'color_chip.primary'
                    }
                  >
                    {item.menuDataNm}
                  </Text>
                  {item.isHighLight === 'Y' && <DotBadge size="sm" />}
                </HStack>
              </GroupCategoryItemWrap>
            );
          })}
        </CategoryListWrap>
        <Divider backgroundColor="color_chip.gray.100" />
        {enteredGroupCategoryNo !== '' && (
          <>
            <CategoryListWrap height="100%" overflowY="auto">
              {enteredGroupCategory?.middleCategoryList &&
                enteredGroupCategory.middleCategoryList.map((item) => {
                  return (
                    <>
                      <MiddleCategoryItemWrap
                        key={`category-menu-depth2-text-${item.categoryNo}-${item.categoryName}`}
                        onMouseEnter={() =>
                          handleEnteredMiddleCategoryNo(item.categoryNo)
                        }
                        onClick={() =>
                          (window.location.href = item.linkUrl || '/')
                        }
                        backgroundColor={
                          enteredMiddleCategoryNo === item.categoryNo
                            ? 'color_chip.gray.100'
                            : 'color_chip.white'
                        }
                        {...TrackingService.tagGTM(
                          `mall_gnb_category_depth2_button`,
                          {
                            'menu-value': item.categoryName,
                          },
                        )}
                      >
                        <HStack space={2}>
                          <Text
                            variant="label_md_m"
                            color={
                              enteredMiddleCategoryNo === item.categoryNo
                                ? 'color_chip.primary'
                                : 'color_chip.gray.700'
                            }
                          >
                            {item.categoryName}
                          </Text>
                          {item.isHighLight === 'Y' && (
                            <NewBadge variant="style1"></NewBadge>
                          )}
                        </HStack>
                      </MiddleCategoryItemWrap>
                    </>
                  );
                })}
            </CategoryListWrap>
            <Divider backgroundColor="color_chip.gray.100" />
          </>
        )}
        {enteredMiddleCategoryNo !== '' && (
          <>
            <CategoryListWrap>
              {enteredMiddleCategory?.smallCategoryList &&
                enteredMiddleCategory.smallCategoryList.map((item, index) => {
                  return (
                    <>
                      {index < 15 && (
                        <SmallCategoryItemWrap
                          key={`category-menu-depth3-${item.categoryNo}-${item.categoryName}`}
                          onClick={() =>
                            (window.location.href = item.linkUrl || '/')
                          }
                          {...TrackingService.tagGTM(
                            `mall_gnb_category_depth3_button`,
                            {
                              'menu-value': item.categoryName,
                            },
                          )}
                        >
                          <HStack space={2}>
                            <Text
                              variant="label_md_m"
                              color="color_chip.gray.700"
                            >
                              {item.categoryName}
                            </Text>
                            {item.isHighLight === 'Y' && (
                              <NewBadge variant="style1"></NewBadge>
                            )}
                          </HStack>
                        </SmallCategoryItemWrap>
                      )}
                    </>
                  );
                })}
            </CategoryListWrap>
            <CategoryListWrap>
              {enteredMiddleCategory?.smallCategoryList &&
                enteredMiddleCategory.smallCategoryList.map((item, index) => {
                  return (
                    <>
                      {index >= 15 && (
                        <SmallCategoryItemWrap
                          key={`category-menu-depth3-${item.categoryNo}-${item.categoryName}`}
                          onClick={() =>
                            (window.location.href = item.linkUrl || '/')
                          }
                          {...TrackingService.tagGTM(
                            `mall_gnb_category_depth3_button`,
                            {
                              'menu-value': item.categoryName,
                            },
                          )}
                        >
                          <HStack space={2}>
                            <Text
                              variant="label_md_m"
                              color="color_chip.gray.700"
                            >
                              {item.categoryName}
                            </Text>
                            {item.isHighLight === 'Y' && (
                              <NewBadge variant="style1"></NewBadge>
                            )}
                          </HStack>
                        </SmallCategoryItemWrap>
                      )}
                    </>
                  );
                })}
            </CategoryListWrap>
          </>
        )}
      </HStack>
    </CategoryMenuWrap>
  );
}

export default CategoryMenu;

const CategoryMenuWrap = styled(VStack)<{ isShow: boolean }>`
  position: absolute;
  top: 34px;
  left: -77px;
  display: ${({ isShow }) => (isShow ? `flex` : 'none')};
`;

const GroupCategoryItemWrap = styled(HStack)`
  cursor: pointer;
  display: flex;
  width: 216px;
  height: 34px;
  padding: 10px 24px;
  }
`;

const MiddleCategoryItemWrap = styled(HStack)`
  cursor: pointer;
  display: block;
  width: 216px;
  height: 34px;
  padding: 10px 24px;
  }
`;

const SmallCategoryItemWrap = styled(HStack)`
  cursor: pointer;
  display: block;
  width: 216px;
  height: 34px;
  padding: 10px 24px;
  &: hover {
    & > div {
      color: black;
    }
  }
`;

const CategoryListWrap = styled(VStack)`
  padding: 8px 0px;
`;

const Divider = styled(Box)`
  height: 100%;
  width: 1px;
`;
