import type { HttpClient } from '@store/base';
import {
  GetDisplayInfoRequest,
  GetDisplayInfoResponse,
  GetGnbListRequest,
  GetGnbListResponse,
  GetMenusRequest,
  GetMenusResponse,
  GetPlanListRequest,
  GetTemplateInfoRequest,
  GetTemplateInfoResponse,
  GetPlanListResponse,
  GetSectionMenusRequest,
  GetSectionMenusResponse,
  GetAppDownloadInfoRequest,
  GetAppDownloadInfoResponse,
} from '../../model';

export class GetMenusGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec(req: GetMenusRequest) {
    return await this.httpClient.request<GetMenusResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/content-service/api/v1/display/menus`,
      body: req,
    });
  }
}

export class GetSectionMenusGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec(req: GetSectionMenusRequest) {
    return await this.httpClient.request<GetSectionMenusResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/display-service/api/v1/menus/main`,
      body: req,
    });
  }
}

export class GetHamburgerMenusGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec(req: GetMenusRequest) {
    return await this.httpClient.request<GetSectionMenusResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/display-service/api/v1/menus`,
      body: req,
    });
  }
}

export class GetAppDownloadInfoGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec(req: GetAppDownloadInfoRequest) {
    return await this.httpClient.request<GetAppDownloadInfoResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/display-service/api/v1/app-induce/${req.appInduceField}`,
      body: req,
    });
  }
}

export class GetDisplayInfoGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec(req: GetDisplayInfoRequest) {
    return await this.httpClient.request<GetDisplayInfoResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/content-service/api/v1/display/display-info`,
      body: req,
    });
  }
}

export class GetGnbListGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec(req: GetGnbListRequest) {
    return await this.httpClient.request<GetGnbListResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/content-service/api/v1/display/gnb-list`,
      body: req,
    });
  }
}

export class GetTemplateInfoGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec(req: GetTemplateInfoRequest) {
    return await this.httpClient.request<GetTemplateInfoResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/content-service/api/v1/display/template-info`,
      body: req,
    });
  }
}

export class GetPlanListGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec(req: GetPlanListRequest) {
    return await this.httpClient.request<GetPlanListResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/content-service/api/v1/display/plan-list`,
      body: req,
    });
  }
}
