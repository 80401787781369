import React from 'react';
import { NextSeo } from 'next-seo';
import { displayServiceServerGateway } from '@display-component/pc';
import { axios } from '@/di-container/axios';
import AppLayout from '@/components/AppLayout';
import { Home } from '@/feat-components/Home';
import { DISPLAY_SERVICE_MAIN_PC } from '@display-component/shared';
import { wrapper } from '@/redux/store';

const HomePage = (props: any) => {
  return (
    <>
      <NextSeo
        noindex={process.env.NEXT_PUBLIC_STAGE !== 'production'}
        nofollow={process.env.NEXT_PUBLIC_STAGE !== 'production'}
        openGraph={{
          description:
            '한샘의 올인원 인테리어 솔루션, 오직 한샘몰에서만 만날 수 있는 홈퍼니싱 가구 혜택과 인테리어 시공사례까지! 더 나은 우리집을 만나보세요',
        }}
      />
      {props?.data?.data && (
        <AppLayout
          isFooterExist={false}
          layoutType="full"
          isGnbArea={false}
          isGnbExist={false}
          isQuickExist={false}
          isHeaderExist={true}
          isHeaderBackgroundColor={false}
          isWelcomePage={true}
          content={<Home data={props.data.data} />}
        />
      )}
    </>
  );
};

export const getServerSideProps = wrapper.getServerSideProps(
  () => async (ctx) => {
    const gateway = await displayServiceServerGateway(
      process.env.NEXT_PUBLIC_BASE_API_HOST || '',
      axios,
    ).getTemplateComponents.exec(
      {
        pageIdentificationValue: DISPLAY_SERVICE_MAIN_PC,
      },
      ctx.req.headers.cookie || '',
    );

    return {
      props: { data: gateway },
    } as any;
  },
);

export default HomePage;
